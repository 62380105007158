import React from 'react';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import Button from '../../components/Button';

const CareerCallout = ({ title, description, path, name, job, img }) => (
  <Section className="career-callout">
    <Container>
      <Row verticalAlignCenter>
        <Column medium={5} mediumOffset={1}>
          <h2>{title}</h2>
          <p>{description}</p>
          <Button to={path}>Learn more about this career</Button>
        </Column>
        <Column medium={6} xlarge={5}>
          <div className="career-callout__imgs">
            <Image filename={img} className="career-callout__headshot" />
            <Image
              filename="career-callout-dashes.svg"
              className="career-callout__dashes"
            />
            <div className="career-callout__name">
              <div className="text-bold">{name}</div>
              <div>{job}</div>
            </div>
          </div>
        </Column>
      </Row>
    </Container>
  </Section>
);

export default CareerCallout;
