import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Resource from '../components/Resource';
import HeroImage from '../images/classroom-hero-img-1400x366-2x.jpg';
import DigitalLessonBundle from '../components/DigitalLessonBundle';
import CareerCallout from './partials/_CareerCallout';
import { LANGUAGE } from '../utils/constants';
import HeroV2 from '../components/HeroV2';

const textOverlay = (
  <div className="home__text_overlay_container">
    <Container fullWidth={true}>
      <div className="home__text_overlay_inner_container">
        <span className="home__text_overlay_title">Classroom Resources</span>
      </div>
    </Container>
  </div>
);

const ClassroomResourcesPage = ({ data }) => (
  <Layout title="Classroom Resources">
    <HeroV2
      mediaSource="classroom-hero-img-1400x366-2x.jpg"
      textOverlay={textOverlay}
    />
    <Section>
      <Container>
        <Row>
          <Column size={8}>
            <h2>Classroom conversation starters</h2>
            <p>
              Inspire the digital defenders and STEM innovators of the future
              TODAY! Engage your young problem solvers with hands-on
              investigations into the exciting world of Cybersecurity. These
              standards-aligned resources give you a step-by-step guide to
              helping students soar in cyber.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <DigitalLessonBundle
      title="Digital Lesson Bundle"
      resource1={
        <Resource
          img="classroom-resources/classroom-resources-dlb-secure-network-presentation.png"
          title="Secure the Network"
          audience="Grades 5–9"
          duration="Two 60-minute class sessions"
          description={
            <>
              <p>
                Learn about the evolving cybersecurity technologies that are
                being applied by businesses to ensure that their networks remain
                protected from cyberattack. Students will get a chance to
                imagine their own idea for a new business and investigate the
                concept of Internet security as they build an “online security
                map” for their company.
              </p>

              <p>
                Use the PowerPoint presentation and companion Educator Guide to
                explore these lessons over the course of two 60-minute class
                sessions.
              </p>
            </>
          }
          actions={[
            {
              label: 'Powerpoint Presentation',
              dropdown: true,
              actions: [
                {
                  label: `${LANGUAGE.en}`,
                  download:
                    '/files/docs/Mastercard-G4T-DLB-SecureTheNetwork.pptx',
                  downloadCloseOnClick: true,
                },
                {
                  label: `${LANGUAGE.ca}`,
                  download:
                    '/docs/Mastercard-G4T-DLB-SecureTheNetwork-CA-FR.pptx',
                  downloadCloseOnClick: true,
                },
              ],
            },
          ]}
        />
      }
      resource2={
        <Resource
          img="classroom-resources/classroom-resources-dlb-secure-network-guide.png"
          title="Secure the Network"
          audience="Grades 5–9"
          duration="Two 60-minute class sessions"
          description="Plan your lessons around key cybersecurity concepts
          like network security, zero-trust architecture, and personal data
          collection using this companion Educator Guide. "
          actions={[
            {
              label: 'Educator Guide',
              dropdown: true,
              actions: [
                {
                  label: `${LANGUAGE.en}`,
                  download:
                    '/docs/Mastercard-Girls4Tech-DLB-SecureTheNetwork-V1.0.pdf',
                  downloadCloseOnClick: true,
                },
                {
                  label: `${LANGUAGE.ca}`,
                  download:
                    '/docs/Mastercard-G4T-DLB-SecureTheNetwork-CA-FR.pdf',
                  downloadCloseOnClick: true,
                },
              ],
            },
          ]}
        />
      }
    />
    <DigitalLessonBundle
      title="Digital Lesson Bundle"
      resource1={
        <Resource
          img="classroom-resources/classroom-resources-dlb-ppt.jpg"
          title="All Things Digital"
          audience="Grades 5–9"
          description={
            <>
              <p>
                Take students on an interactive journey through the evolution of
                technology with this series of hands-on resources. Students will
                learn how technology is developed, how far it's come, and where
                it's going, before embarking on a “rescue mission” that brings
                to life the purpose and role of Binary Code.
              </p>

              <p>
                A PowerPoint presentation and companion Educator Guide provide
                all the tools you need to inspire students over three 45-minute
                sessions and beyond.
              </p>
            </>
          }
          actions={[
            {
              label: 'Powerpoint Presentation',
              dropdown: true,
              actions: [
                {
                  label: `${LANGUAGE.en}`,
                  download: '/docs/Mastercard-DLB-AllThingsDigital.pptx',
                  downloadCloseOnClick: true,
                },
                {
                  label: `${LANGUAGE.ca}`,
                  download:
                    '/files/docs/Mastercard-Girls4Tech-DLB-allthingsdigital_French_Canadian.pptx',
                  downloadCloseOnClick: true,
                },
              ],
            },
          ]}
        />
      }
      resource2={
        <Resource
          img="classroom-resources/classroom-resources-dlb-educator-guide.jpg"
          title="All Things Digital"
          audience="Grades 5–9"
          description="Facilitate lessons on technological innovation in general and binary code in particular with this comprehensive resource, featuring handy educator prep, background info, lesson guides, and supplemental resources."
          actions={[
            {
              label: 'Educator Guide',
              dropdown: true,
              actions: [
                {
                  label: `${LANGUAGE.en}`,
                  download: '/docs/Mastercard-DLB-AllThingsDigitalEdGuide.pdf',
                  downloadCloseOnClick: true,
                },
                {
                  label: `${LANGUAGE.ca}`,
                  download:
                    '/docs/Mastercard-Girls4Tech-DLB_French_Canadian.pdf',
                  downloadCloseOnClick: true,
                },
              ],
            },
          ]}
        />
      }
    />
    <Section className="classroom-resources__activities">
      <Container>
        <h2>Classroom Activities</h2>
        <p className="classroom-resources__activities-intro">
          These standards-aligned resources provide a step-by-step guide to
          sparking students' interest in cyber.
        </p>
        <Row>
          <Column size={4}>
            <Resource
              tophat="Classroom Activity"
              img="classroom-resources/classroom-activity-color-cryptology-433x264-2x.jpg"
              icon="classroom-resources/Icon-lock-White.svg"
              title="Cryptology"
              audience="Grades 6–8"
              description="With more of our lives happening online, we need to devise smarter ways to keep important and sensitive data safe and secure. Discover how encryption and mathematical algorithms are used in cryptology to keep companies one step ahead of cybercrime."
              actions={[
                {
                  label: 'Lesson Plan',
                  dropdown: true,
                  actions: [
                    {
                      label: `${LANGUAGE.en}`,
                      download:
                        '/docs/EN_Mastercard-G4T-ClassroomActivity-Cryptology.pdf',
                      downloadCloseOnClick: true,
                    },
                    {
                      label: `${LANGUAGE.ca}`,
                      download:
                        '/docs/FR_Mastercard-G4T-ClassroomActivity-Cryptology.pdf',
                      downloadCloseOnClick: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              tophat="Classroom Activity"
              img="classroom-resources/classroom-activity-color-FraudDetection-433x264-2x.jpg"
              icon="classroom-resources/Icon-bigdata-White.svg"
              title="Fraud Detection"
              audience="Grades 6–8"
              description="Analyzing malicious online activity requires the ability to see patterns. Learn how Fraud Detectives analyze data to identify anything that doesn't “fit” the pattern, using equal parts algorithm and intuition!"
              actions={[
                {
                  label: 'Lesson Plan',
                  dropdown: true,
                  actions: [
                    {
                      label: `${LANGUAGE.en}`,
                      download:
                        '/docs/EN_Mastercard-G4T-ClassroomActivity-FraudDetection.pdf',
                      downloadCloseOnClick: true,
                    },
                    {
                      label: `${LANGUAGE.ca}`,
                      download:
                        '/docs/FR_Mastercard-G4T-ClassroomActivity-FraudDetection.pdf',
                      downloadCloseOnClick: true,
                    },
                  ],
                },
              ]}
            />
          </Column>

          <Column size={4}>
            <Resource
              tophat="Classroom Activity"
              img="classroom-resources/classroom-activity-color-AllThingsDigital-433x264-2x.jpg"
              icon="classroom-resources/Icon-10101-Long-White.svg"
              title="All Things Digital"
              audience="Grades 6–8"
              description="Digital technology has become an essential part of everyday life, and digital innovation is the key to keeping our information safe online. Explore how “digital convergence” is transforming our lives with the ongoing transition from the physical to the digital."
              actions={[
                {
                  label: 'Lesson Plan',
                  dropdown: true,
                  actions: [
                    {
                      label: `${LANGUAGE.en}`,
                      download:
                        '/docs/EN_Mastercard-G4T-ClassroomActivity-DigitalConvergence.pdf',
                      downloadCloseOnClick: true,
                    },
                    {
                      label: `${LANGUAGE.ca}`,
                      download:
                        '/docs/FR_Mastercard-G4T-ClassroomActivity-DigitalConvergence.pdf',
                      downloadCloseOnClick: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              tophat="Classroom Activity"
              img="classroom-resources/classroom-activity-color-BigData-433x264-2x.jpg"
              icon="classroom-resources/Icon-fingerprint-White.svg"
              title="Big Data"
              audience="Grades 6–8"
              description="Data is the modern “gold” of the digital frontier, which means that it must be carefully collected, organized, cleaned up, and analyzed. Show students how effective analysis of big data can help us not only explain the past, but also plan for the future."
              actions={[
                {
                  label: 'Lesson Plan',
                  dropdown: true,
                  actions: [
                    {
                      label: `${LANGUAGE.en}`,
                      download:
                        '/docs/EN_Mastercard-G4T-ClassroomActivity-BigData.pdf',
                      downloadCloseOnClick: true,
                    },
                    {
                      label: `${LANGUAGE.ca}`,
                      download:
                        '/docs/FR_Mastercard-G4T-ClassroomActivity-BigData.pdf',
                      downloadCloseOnClick: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              tophat="Classroom Activity"
              img="classroom-resources/classroom-activity-color-AIforGood-433x264-2x.jpg"
              icon="classroom-resources/Icon-ai-social-good-White.svg"
              title="AI for Social Good"
              audience="Grades 6–8"
              description="A.I. can process astronomical amounts of data in a millisecond! Empower students to use tech to make their world a better place, by understanding that data is a powerful tool to help us understand our toughest and most complex social problems more clearly."
              actions={[
                {
                  label: 'Lesson Plan',
                  dropdown: true,
                  actions: [
                    {
                      label: `${LANGUAGE.en}`,
                      download:
                        '/docs/EN_Mastercard-G4T-ClassroomActivity-AIforSocialGood.pdf',
                      downloadCloseOnClick: true,
                    },
                    {
                      label: `${LANGUAGE.ca}`,
                      download:
                        '/docs/FR_Mastercard-G4T-ClassroomActivity-AIforSocialGood.pdf',
                      downloadCloseOnClick: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <CareerCallout
      title="Vulnerability Analyst"
      description={
        <>
          Learn how <strong>cyberthreats</strong> can be analyzed and predicted
          to ensure that online businesses and their data are protected.
        </>
      }
      path="/career-profiles/vulnerability-analyst"
      img={'career-profiles/career-profiles-jazmine-headshot.jpg'}
      name="Jazmin Torres"
      job="Vulnerability Analyst, Mastercard"
    />
  </Layout>
);

export default ClassroomResourcesPage;
