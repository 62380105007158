import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Section from '../Section';
import './styles.scss';

const DigitalLessonBundle = ({
  title,
  resource1,
  resource2,
  className,
  ...other
}) => (
  <Section className={classNames('dlb', className)} {...other}>
    <Container>
      <div className="dlb__tophat">{title}</div>
      <div className="dlb__content">
        <Row>
          <Column medium={7}>
            <div className="dlb__resource">{resource1}</div>
          </Column>
          <Column medium={5} large={4}>
            <div className="dlb__resource">{resource2}</div>
          </Column>
        </Row>
      </div>
    </Container>
  </Section>
);

DigitalLessonBundle.defaultProps = {
  title: null,
  resource1: null,
  resource2: null,
  className: null,
};

DigitalLessonBundle.propTypes = {
  /** A title. */
  title: PropTypes.string,
  /** A custom class name */
  className: PropTypes.string,
  /** The first resource. */
  resource1: PropTypes.element.isRequired,
  /** The second resource. */
  resource2: PropTypes.element.isRequired,
};

export default DigitalLessonBundle;
